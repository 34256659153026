import pick from 'lodash/pick';

import { FILTER_KEY, type FiltersKey } from '@/ducks/filters/types';
import { getSearchParamsKeys } from '@/ducks/filters/utils';
import { FMSearchParams } from '@/ducks/fm/types';
import { MNVVSearchParams } from '@/ducks/mnvv/constants';
import { CelebrationSearchParams } from '@/ducks/programs/celebrations/searchParams';
import { ChooseCategorySearchParams } from '@/ducks/voyageInfo/category/searchParams';
import { ChooseSailingSearchParams } from '@/ducks/voyageInfo/sailing/searchParams';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';

export type PickAnyFromSearchParams = PickSearchParams &
  OuterSearchParams & {
    withCelebration?: boolean;
    withCharter?: boolean;
    withChooseCabin?: boolean;
    withChooseSailing?: boolean;
    withFilters?: boolean;
    withFM?: boolean;
    withMNVV?: boolean;
  };

export const pickAnyFromSearchParams = ({
  outerSearchParams,
  paramsToPick,
  withCelebration,
  withCharter,
  withChooseCabin,
  withChooseSailing,
  withFilters,
  withFM,
  withMNVV,
}: PickAnyFromSearchParams = {}) => {
  const filterParams = withFilters ? getAllFiltersKeys() : [];

  const chooseSailingParams = withChooseSailing ? Object.values(ChooseSailingSearchParams) : [];

  const chooseCabinParams = withChooseCabin ? Object.values(ChooseCategorySearchParams) : [];

  const charterParams = withCharter ? ['charterId'] : [];

  const fmParams = withFM ? Object.values(FMSearchParams) : [];

  const mnvvParams = withMNVV ? Object.values(MNVVSearchParams) : [];

  const celebrationParams = withCelebration ? Object.values(CelebrationSearchParams) : [];

  const paramNames = [
    ...filterParams,
    ...chooseSailingParams,
    ...chooseCabinParams,
    ...charterParams,
    ...fmParams,
    ...mnvvParams,
    ...celebrationParams,
    ...(paramsToPick || []),
  ];

  return pickSearchParams({ outerSearchParams, paramsToPick: paramNames });
};

type OuterSearchParams = {
  outerSearchParams?: URLSearchParams;
};

type PickSearchParams = { paramsToPick?: string[] };

const pickSearchParams = ({ outerSearchParams, paramsToPick = [] }: OuterSearchParams & PickSearchParams = {}) => {
  const searchParams = searchParamsAsObject(outerSearchParams || getCurrentSearchParams()) as Record<string, string>;

  return pick(searchParams, paramsToPick);
};

const getAllFiltersKeys = () => [
  ...Object.values(FILTER_KEY).reduce<FiltersKey[]>(
    (keys, key) => [...keys, ...getSearchParamsKeys(key as FiltersKey)],
    [],
  ),
];
