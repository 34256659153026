import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';

import useFocusTrap from '@/ducks/a11y/hooks/useFocusTrap';

import { type RefinementPopoverProps } from './useGetRefinementPopoverProps';

import './Filter.scss';

type TRefinementPopoverProps = {
  children: React.ReactNode;
  className?: string;
  dialogLabel?: string;
  id?: string;
  isInline?: boolean;
} & RefinementPopoverProps;

const RefinementPopover = ({
  children,
  className,
  context,
  dialogLabel,
  floatingStyles,
  focusTrapDisableEvents,
  getFloatingProps,
  id,
  isInline,
  isOpen,
  refs,
  status,
}: TRefinementPopoverProps) => {
  useFocusTrap({
    disableEvents: focusTrapDisableEvents,
    element: refs.floating?.current,
    isOpened: isOpen,
    onlyVisible: true,
  });

  const content = isOpen && (
    <FloatingFocusManager context={context}>
      <div
        aria-label={dialogLabel}
        data-status={status}
        id={id}
        ref={refs.setFloating}
        style={isInline ? undefined : { ...floatingStyles, display: isOpen ? 'block' : 'none' }}
        {...getFloatingProps({ className: cn('filter-popup-transition', className) })}
        aria-modal="true"
        role="dialog"
      >
        {children}
      </div>
    </FloatingFocusManager>
  );

  if (isInline) {
    return content;
  }

  return <>{isOpen && <FloatingPortal>{content}</FloatingPortal>}</>;
};

export default RefinementPopover;
