export enum ChooseCategorySearchParams {
  chosenSubCategoryCode = 'chosenSubCategoryCode',
  isUpsell = 'isUpsell',
  fareClass = 'fareClass',
}

export enum ChooseCategoryOldSearchParams {
  cabinType = 'cabinType',
  cabinCategoryCode = 'cabinCategoryCode',
  cabinCategoryCodeCelebration = 'type',
}
