import type { DefaultContext, HttpOptions, NextLink, Operation } from '@apollo/client';

import { ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { env } from '@/environment';
import { AppAuthenticator, TokenType } from '@/helpers/api/tokens';
import { isServerSide } from '@/helpers/isServerSide';
import { type TWithResponseHeaders } from '@/infra/types/api';

let cookiesImport: unknown;

if (isServerSide()) {
  import('next/headers').then((module) => (cookiesImport = module.cookies));
}

const makeApolloLink = (httpOptions?: HttpOptions, withAuth?: boolean): ApolloLink => {
  const httpLink = new HttpLink({
    ...httpOptions,
    fetchOptions: {
      credentials: 'same-origin',
    },
    uri: `${env.GRAPHQL_URL}`,
  });

  const authContext = withAuth
    ? setContext(async (_, context: DefaultContext): Promise<DefaultContext> => {
        const token = await AppAuthenticator.getInstance().getFreshAccessToken({ tokenType: TokenType.guest });
        return token ? { ...context, headers: { ...context?.headers, Authorization: `bearer ${token}` } } : context;
      })
    : undefined;

  const cookiesContext = setContext(
    (_, context: DefaultContext): DefaultContext => ({
      ...context,
      headers: {
        ...context?.headers,
        Cookie: isServerSide() && cookiesImport ? cookiesImport().toString() : document.cookie,
      },
    }),
  );

  const responseContext = new ApolloLink((operation: Operation, forward: NextLink) =>
    forward(operation).map((fetchResult) => {
      const { onResponseHeaders, response } = operation.getContext() as TWithResponseHeaders & { response: Response };
      onResponseHeaders?.(response?.headers);
      return fetchResult;
    }),
  );

  return ApolloLink.from([authContext, cookiesContext, responseContext, httpLink].filter(Boolean));
};

export default makeApolloLink;
