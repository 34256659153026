import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import cn from 'classnames';
import { memoize } from 'lodash';
import intersection from 'lodash/intersection';
import { useSelector } from 'react-redux';

import DisabledSailorPopup from '@/components/DisabledSailorPopup';
import { Button } from '@/components/elements';
import Flyout from '@/components/Flyout';
import Close from '@/components/Icon/Close';
import Filter from '@/components/Icon/FilterOption';
import DurationRefinement from '@/components/ResultRefinements/DurationRefinement';
import RefinementPopoverMobileHeader from '@/components/ResultRefinements/RefinementPopover/MobileHeader';
import TravelPartyRefinement from '@/components/ResultRefinements/TravelPartyRefinement';
import UIResource from '@/components/UIResource';
import breakpoints from '@/constants/breakpoints';
import { ArrowButtonToggleAnimated } from '@/containers/VoyagePlanner/ChooseCabin/components/ArrowButtonToggleAnimated';
import Status from '@/ducks/a11y/components/Status';
import useActionStatus from '@/ducks/a11y/hooks/useActionStatus';
import { useUIResource } from '@/ducks/common/resources';
import { selectSettings } from '@/ducks/common/settings/selectors';
import { useAnyAdvancedFilterApplied } from '@/ducks/filters/hooks';
import { resetAllFilters } from '@/ducks/filters/setters';
import { selectFilteredSailings } from '@/ducks/pages/chooseVoyage/selectors';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import enforceArray from '@/helpers/enforceArray';
import useOnKeyDown from '@/hooks/useOnKeyDown';
import useOnWindowResize from '@/hooks/useOnWindowResize';
import tagmanager from '@/tagmanager';

import CabinTypeFilter from './CabinTypeFilter';
import DeparturePortFilter from './DeparturePortFilter';
import FilterRefinementMobileView from './FilterRefinementMobileView';
import PriceRangeFilter from './PriceRangeFilter';
import ShipFilter from './ShipFilter';

import './AdvancedFilterRefinement.scss';

const UI_RESOURCE_POOL = {
  applyFilterText: 'AdvancedFilter.cta',
};

const RESET_STATUS_TEXT = 'Reset button clicked';

const CLOSE_BUTTON_ID = 'AdvancedFilterCloseButton';

const AdvancedFilterRefinement = ({
  closeFilterModal,
  collapseSection,
  expandedSections,
  expandSection,
  isOpen,
  refinementFiltersData,
}) => {
  const [isDisabledSailorModalOpen, setIsDisabledSailorModalOpen] = useState(false);
  const [isOpenRefinementSection, setIsOpenRefinementSection] = useState(false);
  const anyAdvancedFilterApplied = useAnyAdvancedFilterApplied();

  const configServiceData = useSelector(selectSettings);
  const filteredSailingsCount = useSelector(selectFilteredSailings)?.length || 0;
  const isLoading = useSelector(selectIsFilteringLoading);

  const hasResults = filteredSailingsCount > 0;
  const hasExpansions = !!expandedSections?.length;

  const label = isLoading ? '' : filteredSailingsCount;

  const isMobile = useOnWindowResize(() => window.innerWidth < breakpoints.md) ?? false;
  const applyFilterText = useUIResource(UI_RESOURCE_POOL.applyFilterText, { n: label });

  const checkFilterAvailable = useCallback(
    (filterName) => !!intersection(enforceArray(filterName), expandedSections).length,
    [expandedSections],
  );

  const onApplyFilters = useCallback(
    (e) => {
      e.preventDefault();
      closeFilterModal();
      tagmanager.tracker.voyagesFilter.trackShowFiltersResult({
        label: applyFilterText,
        results: filteredSailingsCount,
      });
    },
    [applyFilterText, closeFilterModal, filteredSailingsCount],
  );

  const onCloseClick = useCallback(
    (e) => {
      e.preventDefault();
      closeFilterModal();
      tagmanager.tracker.voyagesFilter.trackHideFilters();
    },
    [closeFilterModal],
  );

  const onDisabledSailorModalClick = useCallback(() => {
    setIsDisabledSailorModalOpen(!isDisabledSailorModalOpen);
  }, [isDisabledSailorModalOpen, setIsDisabledSailorModalOpen]);

  const onResetFilters = useCallback(() => {
    document.getElementById(CLOSE_BUTTON_ID)?.focus();
    resetAllFilters();
  }, []);

  const { actionWithStatus: resetWithStatus, closeStatus, isStatusShown } = useActionStatus(onResetFilters);

  const onSailorsericeBtnClick = useCallback(() => {
    setIsDisabledSailorModalOpen(false);
    const landingPageUrl = configServiceData?.ContactUsUrl;
    window.open(landingPageUrl, '_blank');
  }, [configServiceData, setIsDisabledSailorModalOpen]);

  const toggleFilterState = useCallback(
    (section) =>
      memoize((state) => {
        (state ? expandSection : collapseSection)(section);
      }),
    [collapseSection, expandSection],
  );

  const toggleIsOpenRefinementSection = useCallback(() => {
    if (!isOpenRefinementSection) {
      tagmanager.tracker.voyagesFilter.trackShowFilters();
    }

    setIsOpenRefinementSection(!isOpenRefinementSection);
  }, [isOpenRefinementSection, setIsOpenRefinementSection]);

  const toggleIsOpenRefinementSectionOnKeyDown = useOnKeyDown(toggleIsOpenRefinementSection);

  const handleOnApplyFilters = useOnKeyDown(onApplyFilters);

  return (
    <>
      <Flyout
        appear={false}
        delay={0}
        direction="right"
        focusOnOpenSelector={`#${CLOSE_BUTTON_ID}`}
        hideCrossButton
        onDismiss={closeFilterModal}
        open={isOpen}
      >
        <div className="AdvancedFilter__section">
          {isMobile ? (
            <>
              <RefinementPopoverMobileHeader closeButtonId={CLOSE_BUTTON_ID} onClickClose={onCloseClick}>
                <UIResource id="AdvancedFilter.header.title" />
              </RefinementPopoverMobileHeader>

              <div className={cn('FilterRefinement', { 'no-scroll': hasExpansions && isMobile })}>
                <FilterRefinementMobileView refinementFiltersData={refinementFiltersData} />
                <div className="advancedFilterView">
                  <div
                    aria-expanded={isOpenRefinementSection}
                    className="heading"
                    onClick={toggleIsOpenRefinementSection}
                    onKeyDown={toggleIsOpenRefinementSectionOnKeyDown}
                    role="button"
                    tabIndex={hasExpansions ? -1 : 0}
                  >
                    <div className="title">
                      <div className="title__icon">
                        <Filter aria-label="Filter Option" />
                      </div>
                      <span className="title__text">
                        <UIResource id="AdvancedFilter.title" />
                      </span>
                    </div>
                    <ArrowButtonToggleAnimated direction={isOpenRefinementSection ? 'up' : 'down'} />
                  </div>
                  {isOpenRefinementSection && (
                    <div className="content">
                      <TravelPartyRefinement
                        closeFilterModal={closeFilterModal}
                        isOpenFilterSection={checkFilterAvailable(['travelParty', 'accessible'])}
                        onDisabledSailorModalClick={onDisabledSailorModalClick}
                        toggleFilterState={toggleFilterState('travelParty')}
                      />
                      <CabinTypeFilter
                        closeFilterModal={closeFilterModal}
                        isOpenFilterSection={checkFilterAvailable('cabinType')}
                        toggleFilterState={toggleFilterState('cabinType')}
                      />
                      <DurationRefinement
                        closeFilterModal={closeFilterModal}
                        isOpenFilterSection={checkFilterAvailable(['duration', 'weekend'])}
                        toggleFilterState={toggleFilterState('duration')}
                      />
                      <PriceRangeFilter
                        closeFilterModal={closeFilterModal}
                        isOpenFilterSection={checkFilterAvailable('price')}
                        toggleFilterState={toggleFilterState('price')}
                      />
                      <ShipFilter
                        closeFilterModal={closeFilterModal}
                        isOpenFilterSection={checkFilterAvailable('ship')}
                        toggleFilterState={toggleFilterState('ship')}
                      />
                      <DeparturePortFilter
                        closeFilterModal={closeFilterModal}
                        isOpenFilterSection={checkFilterAvailable('port')}
                        toggleFilterState={toggleFilterState('port')}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="AdvancedFilter__main">
              <div className="AdvancedFilter__header" id="AdvancedFilter__header">
                <button className="close" onClick={onCloseClick} tabIndex={-1}>
                  <Close aria-label="Close" id="AdvancedFilterCloseButton" tabIndex={0} type="button" />
                </button>
                <h2 className="title">
                  <UIResource id="AdvancedFilter.title" />
                </h2>
              </div>
              <div className="AdvancedFilter__body">
                <TravelPartyRefinement
                  closeFilterModal={closeFilterModal}
                  isOpenFilterSection={checkFilterAvailable(['travelParty', 'accessible'])}
                  onDisabledSailorModalClick={onDisabledSailorModalClick}
                  toggleFilterState={toggleFilterState('travelParty')}
                />
                <CabinTypeFilter
                  closeFilterModal={closeFilterModal}
                  isOpenFilterSection={checkFilterAvailable('cabinType')}
                  toggleFilterState={toggleFilterState('cabinType')}
                />
                <DurationRefinement
                  closeFilterModal={closeFilterModal}
                  isOpenFilterSection={checkFilterAvailable(['duration', 'weekend'])}
                  toggleFilterState={toggleFilterState('duration')}
                />
                <PriceRangeFilter
                  closeFilterModal={closeFilterModal}
                  isOpenFilterSection={checkFilterAvailable('price')}
                  toggleFilterState={toggleFilterState('price')}
                />
                <ShipFilter
                  closeFilterModal={closeFilterModal}
                  isOpenFilterSection={checkFilterAvailable('ship')}
                  toggleFilterState={toggleFilterState('ship')}
                />
                <DeparturePortFilter
                  closeFilterModal={closeFilterModal}
                  isOpenFilterSection={checkFilterAvailable('port')}
                  toggleFilterState={toggleFilterState('port')}
                />
              </div>
            </div>
          )}
          <div className="AdvancedFilter__footer FooterActionBar">
            <div className="row">
              {anyAdvancedFilterApplied ? (
                <button className="col col1 FooterActionBarResetButton" onClick={resetWithStatus}>
                  <UIResource id="AdvancedFilter.resetFilter.txt" />
                </button>
              ) : (
                <div className="col col1" />
              )}
              <div className="col col2">
                <Button
                  block
                  disabled={!hasResults && !isLoading}
                  id="PriceBreakdownCheckout"
                  onClick={onApplyFilters}
                  onKeyDown={handleOnApplyFilters}
                  variant="primary"
                >
                  {applyFilterText}
                </Button>
              </div>
            </div>

            <Status hideAfter={1000} isShown={isStatusShown} onHide={closeStatus} srOnly>
              {RESET_STATUS_TEXT}
            </Status>
          </div>
        </div>
      </Flyout>
      <div className="AdvancedFilter__section" id="advanced-filters-mobile-portal">
        {/* mobile filters */}
      </div>
      <DisabledSailorPopup
        onDismissClick={onDisabledSailorModalClick}
        onSailorsericeBtnClick={onSailorsericeBtnClick}
        showModal={isDisabledSailorModalOpen}
      />
    </>
  );
};

AdvancedFilterRefinement.propTypes = {
  closeFilterModal: PropTypes.func.isRequired,
  collapseSection: PropTypes.func,
  expandedSections: PropTypes.arrayOf(PropTypes.string),
  expandSection: PropTypes.func,
  renderMainFilter: PropTypes.func,
};

export default AdvancedFilterRefinement;
