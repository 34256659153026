import React from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import CabinPrice from '@/components/CabinCard/CabinPrice';
import FormCheck from '@/components/elements/FormCheck';
import UIResource from '@/components/UIResource';
import { useUIResourcePool } from '@/ducks/common/resources';
import { selectMetaTypeLabel } from '@/ducks/filters/labels/metaType';
import {
  selectCabins,
  selectCurrencyCode,
  selectMetaType,
  selectPriceType,
  selectSailors,
} from '@/ducks/filters/selectors';
import { setFilter } from '@/ducks/filters/setters';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import { selectDefaultGenericCategoryCodes, selectGenericCategoryCodes } from '@/ducks/pages/chooseVoyage/selectors';
import tracking from '@/ducks/pages/chooseVoyage/tracking';
import { selectIsLockItInFlowCabin } from '@/ducks/programs/lockItInRate/selectors';
import { getSafeElementId } from '@/helpers/util';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { useAppDispatch, useAppSelector } from '@/store';

import type { BaseFilterProps } from '../types';

import AdvancedFilterHOC from '../AdvancedFilterHOC';
import useGetBaseFilterProps from '../useGetBaseFilterProps';

import './CabinTypeRefinement.scss';

type CabinTypeFilterProps = BaseFilterProps;

const CabinTypeFilter = (props: CabinTypeFilterProps) => {
  const { closeFilterModal, isOpenFilterSection } = props;
  const dispatch = useAppDispatch();

  const { toggleFilterSectionState } = useGetBaseFilterProps(props);

  const { defaultValue } = useUIResourcePool({
    defaultValue: 'AdvancedFilter.cabintype.Default.value',
  });

  const selectedType = useAppSelector(selectMetaType);
  const cabinTypes = useAppSelector(getFiltersOptions).cabinTypes;
  const cabins = useAppSelector(selectCabins);
  const currencyCode = useAppSelector(selectCurrencyCode);
  const defaultGenericCategoryCodes = useAppSelector(selectDefaultGenericCategoryCodes);
  const genericCategoryCodes = useAppSelector(selectGenericCategoryCodes);
  const isLockItInFlow = useAppSelector(selectIsLockItInFlowCabin);
  const label = useAppSelector(selectMetaTypeLabel);
  const priceType = useAppSelector(selectPriceType);
  const sailors = useAppSelector(selectSailors);

  const onCabinTypeChange = (value?: string) => {
    const newState = value?.trim() === defaultValue.trim() ? undefined : value;
    setFilter.metaType(newState);
    dispatch(tracking.trackCabinType(newState || defaultValue));
  };

  const getAmountFromGenericCatergoryCodes = (code: string) => {
    if (isEmpty(genericCategoryCodes)) {
      const category = defaultGenericCategoryCodes?.find(
        (masterCategory) => masterCategory.genericCategoryCode === code,
      );
      return category;
    }
    const category = genericCategoryCodes?.find((masterCategory) => masterCategory.genericCategoryCode === code);
    return category;
  };

  const isEnabled = (code: string) => {
    const isAmountPrsnt = getAmountFromGenericCatergoryCodes(code);
    return !!isAmountPrsnt;
  };

  const defaultValueSelected = selectedType === defaultValue || selectedType === null;

  const resetValue = () => {
    if (selectedType !== null && selectedType !== defaultValue) {
      onCabinTypeChange();
    }
  };

  return (
    <AdvancedFilterHOC
      className="CabinTypeFilter__main"
      closeFilterModal={closeFilterModal}
      defaultValue={label}
      filterName="AdvancedFilter.CabinType"
      isOpen={isOpenFilterSection}
      toggleFilterSectionState={toggleFilterSectionState}
    >
      <div className="cabinType-list action">
        {cabinTypes &&
          cabinTypes.map((meta) => {
            const disabled = !isEnabled(meta.code);
            const radioLabelId = getSafeElementId(`cabin-type-${meta.code}`);
            const onChange = () => {
              if (selectedType !== meta.code && !disabled && !isLockItInFlow) {
                onCabinTypeChange(meta.code);
              }
            };

            return (
              <div className="CabinTypeRefinement__card" key={meta.code}>
                <div className="CabinTypeRefinement__cardRadio" id="cardRadio">
                  <FormCheck
                    aria-labelledby={radioLabelId}
                    checked={selectedType === meta.code}
                    disabled={disabled || isLockItInFlow}
                    id={meta.code}
                    inputTabIndex={0}
                    name={meta.name}
                    onChange={onChange}
                    role="presentation"
                    tabIndex={-1}
                    type="radio"
                    value={meta.code}
                  />
                </div>
                <div className="CabinTypeRefinement__cardImage">
                  {meta.imageUrl && (
                    <div className="CabinTypeRefinement__image">
                      <img alt="CabinTypeRefinement-img" aria-hidden src={meta?.imageUrl?.src} />
                    </div>
                  )}

                  <div className="CabinTypeRefinement__cardDesc" id={radioLabelId}>
                    <span
                      className={classNames('CabinTypeRefinement__name', {
                        selected: selectedType === meta.code,
                      })}
                    >
                      {meta.name}
                    </span>

                    {!disabled && !isLockItInFlow ? (
                      <p className="price">
                        <UIResource id="AdvancedFilter.CabinType.from" />{' '}
                        <CabinPrice
                          abTestPriceType={
                            priceType === FiltersPriceType.sailorPerNight ? FiltersPriceType.perSailor : priceType
                          }
                          cabins={cabins}
                          currencyCode={currencyCode}
                          isForceLineBreak={false}
                          isFromAdvFilter
                          sailors={sailors}
                          startingPrice={getAmountFromGenericCatergoryCodes(meta.code)}
                        />{' '}
                        {priceType === FiltersPriceType.perCabin ? (
                          <UIResource id="AdvancedFilter.CabinType.perCabin.type" />
                        ) : (
                          <UIResource id="AdvancedFilter.CabinType.perSailor.type" />
                        )}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        <div className="CabinTypeRefinement__card default">
          <div className="CabinTypeRefinement__cardRadio" id="cardRadio">
            <FormCheck
              aria-labelledby="cabin-type__any-best-available"
              checked={defaultValueSelected}
              id="default"
              inputTabIndex={0}
              name="default"
              onChange={resetValue}
              role="presentation"
              tabIndex={-1}
              type="radio"
              value={defaultValue}
            />
          </div>
          <p
            className={`CabinTypeRefinement__description default ${defaultValueSelected ? 'selected' : 'not-selected'}`}
            id="cabin-type__any-best-available"
          >
            {defaultValue}
          </p>
        </div>
      </div>
    </AdvancedFilterHOC>
  );
};

export default CabinTypeFilter;
