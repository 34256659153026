import cn from 'classnames';

export type RefinementPopoverHeaderProps = {
  children: React.ReactNode;
  className?: string;
  icon?: React.ReactElement;
};

import styles from './Header.module.scss';

const RefinementPopoverHeader = ({ children, className, icon }: RefinementPopoverHeaderProps) => {
  return (
    <div className={cn(styles.root, className)}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={cn(styles.title, !!icon && styles.withIcon)}>{children}</span>
    </div>
  );
};

export default RefinementPopoverHeader;
