import { useSearchParams } from 'next/navigation';
import React, { useCallback, useRef } from 'react';

import { pick } from 'lodash';

import type { RefinementFiltersData } from '@/components/ResultRefinements/RefinementPopover/useRefinementFiltersData';

import FilterOption from '@/components/Icon/FilterOption';
import UIResource from '@/components/UIResource';
import { selectTravelDatesLabel } from '@/ducks/filters/labels/dates';
import { selectDestinationsPillLabel } from '@/ducks/filters/labels/destinations';
import { resetFilters } from '@/ducks/filters/setters';
import { FILTER_KEY } from '@/ducks/filters/types';
import { isFiltersApplied } from '@/ducks/filters/utils';
import useOnKeyDown from '@/hooks/useOnKeyDown';
import { useAppSelector } from '@/store';

import RefinementPill from './RefinementPill';

import './MainFilter.scss';

type MainFilterProps = {
  onOpenFilterModal: (event: React.UIEvent) => void;
  refinementFiltersData: RefinementFiltersData;
};

const MainFilter = ({ onOpenFilterModal, refinementFiltersData }: MainFilterProps) => {
  const primaryContainer = useRef(null);
  const searchParams = useSearchParams();
  const onOpenFilterModalWithKeyDown = useOnKeyDown(onOpenFilterModal, { isDefaultPrevented: true });
  const destinationsLabel = useAppSelector(
    useCallback((state) => selectDestinationsPillLabel(state, searchParams), [searchParams]),
  );
  const travelDatesLabel = useAppSelector(selectTravelDatesLabel);

  const { destinations, travelDates } = refinementFiltersData;

  const isTravelDatesApplied = isFiltersApplied([FILTER_KEY.dateFrom, FILTER_KEY.dateTo], searchParams);

  const resetTravelDates = useCallback(() => {
    resetFilters([FILTER_KEY.dateFrom, FILTER_KEY.dateTo]);
  }, []);

  return (
    <div className="NewResultRefinements">
      <div className="NewResultRefinements__newFilterPrimaryRow" id="ResultRefinements__newFilterPrimaryRow">
        <div className="NewResultRefinements__newFilterContainer" id="ResultRefinements__newFilterContainer">
          <div
            aria-label="Refinement filters"
            className="primaryContainer"
            id="primaryContainer"
            ref={primaryContainer}
          >
            <RefinementPill
              {...pick(destinations, ['isOpen', 'disabled', 'buttonRef', 'buttonProps'])}
              label={destinationsLabel}
              titleId="Destination.heading"
            />
            <RefinementPill
              {...pick(travelDates, ['isOpen', 'disabled', 'buttonRef', 'buttonProps'])}
              isApplied={isTravelDatesApplied}
              label={travelDatesLabel}
              onReset={resetTravelDates}
              titleId="DateRefinement.heading"
            />
          </div>
          <div
            className="secondaryContainer"
            onClick={onOpenFilterModal}
            onKeyDown={onOpenFilterModalWithKeyDown}
            role="button"
            tabIndex={0}
          >
            <span aria-hidden="true" className="filtersIconTitle">
              <UIResource id="ResultRefinements.filters" />
            </span>
            <FilterOption aria-label="Show voyage filters" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFilter;
