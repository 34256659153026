import type { TOptional } from '@/types/common';

import { ADDRESS_REQUIRED_FIELDS, CITY_REQUIRED_COUNTRIES, FIELD_NAMES } from '../constants';

export type TCityRequiredCountry = (typeof CITY_REQUIRED_COUNTRIES)[number];

const getAddressRequiredFields = (countryCode: TOptional<string>, countriesWithProvinces: TOptional<string[]>) => {
  const hasProvinces = countryCode && countriesWithProvinces?.includes(countryCode);
  return [
    ...ADDRESS_REQUIRED_FIELDS,
    ...(countryCode && CITY_REQUIRED_COUNTRIES.includes(countryCode) ? [FIELD_NAMES.CITY] : []),
    ...(hasProvinces ? [FIELD_NAMES.STATE] : []),
  ];
};

export default getAddressRequiredFields;
