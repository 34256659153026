export enum CelebrationSearchParams {
  referralCode = 'referralCode',
  referralType = 'referralType',
}

export const getSearchParamsCelebrationFlow = (searchParams: URLSearchParams) => ({
  [CelebrationSearchParams.referralCode]: searchParams.get(CelebrationSearchParams.referralCode),
  [CelebrationSearchParams.referralType]: searchParams.get(CelebrationSearchParams.referralType),
});

export const checkAreCelebrationSearchParamsEmpty = (searchParams: URLSearchParams) =>
  !Object.values(getSearchParamsCelebrationFlow(searchParams)).every(Boolean);
