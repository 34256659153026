import cn from 'classnames';

import RefinementPopoverMobileHeader from '@/components/ResultRefinements/RefinementPopover/MobileHeader';
import ClientOnlyPortal from '@/helpers/clientOnlyPortal';

import Flyout from '../Flyout';

type Props = {
  children: React.ReactNode;
  className?: string;
  closeFilterModal?: (e: React.UIEvent) => void;
  defaultValue?: string;
  isOpen?: boolean;
  onTraverseBack?: (e: React.UIEvent) => void;
  title?: string;
  toggleButtonRef?: React.RefObject<HTMLElement>;
};

const backButtonClassName = 'AdvancedFilterMobileHOCBackButton';
const closeButtonClassName = 'AdvancedFilterMobileHOCCloseButton';

const AdvancedFilterMobileHOC = ({
  children,
  className,
  closeFilterModal,
  defaultValue,
  isOpen,
  onTraverseBack,
  title,
  toggleButtonRef,
}: Props) => {
  return (
    <>
      {isOpen && (
        <ClientOnlyPortal id="advanced-filters-mobile-portal">
          <Flyout
            appear={false}
            className="AdvancedFilterHOC_mobile"
            delay={0}
            direction="right"
            focusOnCloseSelector={toggleButtonRef?.current}
            focusOnOpenSelector={`button.${backButtonClassName}`}
            hideCrossButton
            onDismiss={closeFilterModal}
            open={isOpen}
          >
            <div className={cn('row', className)}>
              <div className="col col2">
                <RefinementPopoverMobileHeader
                  backButtonClassName={backButtonClassName}
                  closeButtonClassName={closeButtonClassName}
                  onClickBack={onTraverseBack}
                  onClickClose={closeFilterModal}
                  subHeader={defaultValue}
                >
                  {title}
                </RefinementPopoverMobileHeader>

                <div className="content">{children}</div>
              </div>
            </div>
          </Flyout>
        </ClientOnlyPortal>
      )}
    </>
  );
};

export default AdvancedFilterMobileHOC;
